export type SearchState = {
  keyword: string
  category: string
  tags: string[]
  allData: any
  resultData: any
  fetchedPage: number
  isKeyword: boolean
  isArchive: boolean
}

type Action = {
  type: string
  payload: any
}

export const initialSearchState: SearchState = {
  keyword: '',
  category: '',
  tags: [],
  allData: [],
  resultData: [],
  fetchedPage: 1,
  isKeyword: false,
  isArchive: false,
}

export function searchReducer(state: SearchState, action: Action) {
  switch (action.type) {
    case 'SET_KEYWORD':
      return {
        ...state,
        keyword: action.payload,
      }
    case 'GET_INITIAL_DATA':
      return {
        ...state,
        allData: action.payload,
      }
    case 'SET_FETCHED_PAGE':
      return {
        ...state,
        fetchedPage: action.payload,
      }
    case 'TOGGLE_MODE':
      return {
        ...state,
        isKeyword: action.payload,
      }
    case 'SET_HAS_MORE':
      return {
        ...state,
        hasMore: action.payload,
      }
    case 'SET_CATEGORY':
      return {
        ...state,
        category: action.payload,
      }
    case 'SET_TAGS':
      return {
        ...state,
        tags: action.payload,
      }
    case 'SET_RESULT':
      return {
        ...state,
        resultData: action.payload,
      }
    case 'TOGGLE_ARCHIVE':
      return {
        ...state,
        isArchive: action.payload,
      }
    default:
      return state
  }
}
