import 'firebase/firestore'
import 'firebase/functions'
import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react'
import '@contentful/live-preview/style.css'
import React from 'react'
import GlobalStateProvider from './src/state/GlobalStateProvider'

export function wrapRootElement({ element }) {
  const isBrowser = typeof window !== 'undefined'
  if (isBrowser) {
    return (
      <ContentfulLivePreviewProvider locale="ja-JP">
        <GlobalStateProvider>{element}</GlobalStateProvider>
      </ContentfulLivePreviewProvider>
    )
  } else {
    return <>{element}</>
  }
}
