import React, { createContext, useReducer, useMemo, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import combinereducers from 'react-combine-reducers'
import { searchReducer, initialSearchState } from './SearchState'

export const GlobalStateContext = createContext(null)
export const GlobalDispatchContext = createContext(null)

const GloabalStateProvider: React.FC<{}> = ({ children }) => {
  const [rootReducer, initialState] = combinereducers({
    search: [searchReducer, initialSearchState],
  })
  const [store, dispatch] = useReducer(rootReducer, initialState)
  const contextValue = useMemo(() => [store, dispatch], [store, dispatch])
  const data = useStaticQuery(graphql`
    query SearchQuery {
      allContentfulEvents(
        sort: { fields: date, order: DESC }
        filter: { node_locale: { eq: "ja" } }
      ) {
        edges {
          node {
            id
            title
            summary
            slug
            date
            exhibition
            createdAt(locale: "ja")
            category {
              title
            }
            tags {
              title
            }
            thumbnail {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  `).allContentfulEvents.edges.map((item) => item.node)
  useEffect(() => {
    let fixedData = data.map((item) => {
      if (item.category) item.category = item.category.map((el) => el.title)
      if (item.tags) item.tags = item.tags.map((el) => el.title)
      return item
    })
    dispatch({
      type: 'GET_INITIAL_DATA',
      payload: fixedData,
    })
  }, [])
  return (
    <GlobalStateContext.Provider value={contextValue[0]}>
      <GlobalDispatchContext.Provider value={contextValue[1]}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GloabalStateProvider
